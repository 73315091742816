<template>
    <div class="page-wrap">
     
     <div class="page-main flex-column">
       <div ><router-view></router-view></div>
     </div>  
   </div> 

</template>
<script>
export default {
  name:'forecastapply',
  data(){
       return {

       }
  },
  methods:{
  
  }
}
</script>


